import { DateTime } from "luxon";
import { callApi } from "../common";
import { EarningsConferenceCall, EarningsDetailResponse, EarningsResponse } from "../model";

export const fetchEarnings = async (from: DateTime, to: DateTime): Promise<EarningsResponse[]> => {
  const r = (await callApi(`earnings`, {
    from: from.toMillis(),
    to: to.toMillis(),
  })) as EarningsResponse[];
  return r
    .map((item) => {
      item.eventAt = DateTime.fromISO(item.eventAt.toString());
      item.liveEventAt = item.liveEventAt ? DateTime.fromISO(item.liveEventAt.toString()) : undefined;
      return item;
    })
    .sort((a, b) => b.marketCap - a.marketCap);
};

export const fetchEarningsCalls = async (from: DateTime, to: DateTime): Promise<EarningsResponse[]> => {
  const r = (await callApi(`earnings-calls`, {
    from: from.toMillis(),
    to: to.toMillis(),
  })) as EarningsResponse[];
  return r
    .filter((item) => item.liveEventAt)
    .map((item) => {
      item.eventAt = DateTime.fromISO(item.eventAt.toString());
      item.liveEventAt = item.liveEventAt ? DateTime.fromISO(item.liveEventAt.toString()) : undefined;
      return item;
    })
    .sort((a, b) => b.marketCap - a.marketCap);
};

export const fetchEarningsById = async (id?: string): Promise<EarningsDetailResponse | undefined> => {
  if (id) {
    const r = (await callApi(`earnings-by-id/${id}`)) as EarningsDetailResponse;
    if (r) {
      convertEarningsDetailDateValues(r);
    }
    return r;
  }
};

export const fetchEarningsCallById = async (id?: string): Promise<EarningsDetailResponse | undefined> => {
  if (id) {
    const r = (await callApi(`earnings-call/${id}`)) as EarningsDetailResponse;
    if (r) {
      convertEarningsDetailDateValues(r);
    }
    return r;
  }
};

export const fetchEarningsByTicker = async (ticker: string): Promise<EarningsDetailResponse[]> => {
  const r = (await callApi(`earnings/${ticker}`)) as EarningsDetailResponse[];
  return r
    .map((item) => {
      convertEarningsDetailDateValues(item);
      return item;
    })
    .filter((item) => item.earningsConferenceCalls.length > 0);
};

export const fetchConferenceCalls = async (from: DateTime, to: DateTime): Promise<EarningsConferenceCall[]> => {
  const r = (await callApi(`conference-calls`, {
    from: from.toMillis(),
    to: to.toMillis(),
  })) as EarningsConferenceCall[];
  return r
    .filter((item) => item.eventAt)
    .map((item) => {
      convertConferenceCallDateValues(item);
      return item;
    })
    .sort((a, b) => {
      if (a.ticker === "FED") return -1;
      if (b.ticker === "FED") return 1;
      return b.marketCap - a.marketCap;
    });
};

export const fetchConferenceCallsByTicker = async (ticker: string): Promise<EarningsConferenceCall[]> => {
  const r = (await callApi(`conference-calls/${ticker}`)) as EarningsConferenceCall[];
  return r.map((item) => {
    convertConferenceCallDateValues(item);
    return item;
  });
};

export const fetchConferenceCallById = async (id?: string): Promise<EarningsConferenceCall | undefined> => {
  if (id) {
    const r = (await callApi(`conference-call/${id}`)) as EarningsConferenceCall;
    if (r) {
      convertConferenceCallDateValues(r);
    }
    return r;
  }
};

const convertEarningsDetailDateValues = (item: EarningsDetailResponse) => {
  item.eventAt = DateTime.fromISO(item.eventAt.toString());
  item.createdAt = DateTime.fromISO(item.createdAt.toString());
  item.updatedAt = DateTime.fromISO(item.updatedAt.toString());

  item.earningsConferenceCalls.forEach((call) => {
    convertConferenceCallDateValues(call);
  });
};

const convertConferenceCallDateValues = (item: EarningsConferenceCall) => {
  item.eventAt = DateTime.fromISO(item.eventAt.toString());
};
