import styled from "@emotion/styled";
import { EarningsCallTranscript, EarningsConferenceCall } from "../../api";
import Typography from "../../component/Typography";
import { getElapsedTimeText } from "../../util/time";
import { Colors } from "../../component/Color";
import { Space } from "../../component/space";

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const Time = styled(Typography)`
  width: 70px;
`;

const Content = styled(Typography)`
  flex: 1;
`;

interface LiveSummaryProps {
  conferenceCall: EarningsConferenceCall;
  showTitle?: boolean;
}

export function LiveSummary({ conferenceCall, showTitle = true }: LiveSummaryProps) {
  return (
    <Container>
      {showTitle && (
        <TitleContainer>
          <Space height={20} />
          <Typography variant="10/semibold" color={Colors.white} component="div">
            실시간 요약
          </Typography>
          <Typography variant="8/semibold" color={Colors.gray40} component="div">
            어닝콜 라이브 중 주요 내용을 실시간으로 요약한 스크립트입니다.
          </Typography>
          <Space height={10} />
        </TitleContainer>
      )}
      {conferenceCall?.liveSummary?.summary?.map((item) => (
        <Row>
          <Time variant="5/regular" color={Colors.gray60} component="div">
            {item.s && getElapsedTimeText(item.s)}
          </Time>
          <Content variant="10/medium" color={Colors.gray20} component="div">
            {item.c}
          </Content>
        </Row>
      ))}

      {conferenceCall?.liveSummary?.qna?.map((item) => (
        <>
          <Row>
            <Time variant="5/regular" color={Colors.gray60} component="div">
              {item.s && getElapsedTimeText(item.s)}
            </Time>
            <Content variant="10/medium" color={Colors.gray20} component="div">
              Q: {item.q}
              <Space height={10} />
              A: {item.a}
            </Content>
          </Row>
        </>
      ))}
    </Container>
  );
}
