import styled from "@emotion/styled";
import { Colors } from "../../component/Color";
import IconKoreanTime from "./images/flag_korea.png";
import Typography from "../../component/Typography";
import { MOBILE_BREAKPOINT_2, useIsMobile } from "../../util/mediaquery";
import { Space } from "../../component/space";

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  border-radius: 40px;
  gap: 8px;

  // @media (max-width: ${MOBILE_BREAKPOINT_2}px) {
  //   padding: 6px 10px 6px 8px;
  //   gap: 6px;
  // }
`;

export default function KoreanTime() {
  const isMobile = useIsMobile(2);
  const size = 24;
  return (
    <Container>
      <img src={IconKoreanTime} alt="korean-time" width={size} height={size} />
      <Typography variant={isMobile ? "7.5/semibold" : "8/semibold"} color={Colors.gray40}>
        한국시간
      </Typography>
    </Container>
  );
}
